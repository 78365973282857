<template>
	<w-layout
		column
		class="signature-container"
		:style="`left:${coordsX};top:${coordsY}`"
		@click="onClick($event)"
		@mouseenter="showActions = true"
		@mouseleave="showActions = false"
	>
		<w-layout
			:id="`signature-field-${value.id}`"
			align-center
			column
			draggable="true"
			justify-space-around
			:style="`height:${height}px;width:${width}px;`"
			text-xs-center
			@dragstart="onDragStart($event)"
		>
			<p :class="textSize" v-text="`${signatory.firstname} ${signatory.lastname}`" />
		</w-layout>
		<v-expand-transition>
			<w-layout v-show="showActions" align-center justify-center>
				<w-btn color="white" icon="edit" icon-color="primary" mini @click="editSignatory(model.id)">{{ $t('actions.edit') }}</w-btn>
				<span class="mx-2" />
				<w-btn color="white" icon="delete" icon-color="error" mini @click="deleteSignatureArea(model.id)">{{ $t('actions.delete') }}</w-btn>
			</w-layout>
		</v-expand-transition>
	</w-layout>
</template>

<script>
import SignatureModuleGuard from '@/mixins/ModulesGuards/Signature/SignatureModuleGuard'
import Vue from 'vue'
import AddSignatoryComponent from '@/components/Documents/Signature/Preview/AddSignatory'

export default {
	name: 'SignatureArea',
	mixins: [SignatureModuleGuard],
	props: {
		value: {
			required: true,
			type: Object
		}
	},
	data: function () {
		return {
			coordsX: null,
			coordsY: null,
			height: 0,
			showActions: false,
			resizeObserver: new ResizeObserver(this.resizeObserverHandler),
			width: 0
		}
	},
	computed: {
		model: {
			get: function () {
				return this.value
			},
			set: function (value) {
				this.$emit('input', value)
			}
		},
		pageNumber: function () {
			return this.model.page
		},
		signatory: function () {
			return this.model.signatory
		},
		textSize: function () {
			if (this.width >= 150) {
				return undefined
			}
			if (this.width < 100) {
				return 'caption'
			}
			return 'body-2'
		}
	},
	watch: {
		'value.coords': {
			handler: function () {
				this.computeAreaPosition()
			}
		}
	},
	destroyed: function () {
		this.resizeObserver.unobserve(this.$parent.$el)
	},
	mounted: function () {
		this.resizeObserver.observe(this.$parent.$el)
	},
	methods: {
		computeAreaPosition: function () {
			const { height: realPDFHeight, width: realPDFWidth } = this.getPageRealDimensions(this.pageNumber)

			this.coordsX = `${(this.$parent.$el.offsetWidth / realPDFWidth) * this.model.coords.x}px`
			this.coordsY = `${(this.$parent.$el.offsetHeight / realPDFHeight) * this.model.coords.y}px`
		},
		computeFieldDimensions: function () {
			const { height: realPDFHeight, width: realPDFWidth } = this.getPageRealDimensions(this.pageNumber)

			this.height = (this.$parent.$el.offsetHeight / realPDFHeight) * 61
			this.width = (this.$parent.$el.offsetWidth / realPDFWidth) * 200
		},
		editSignatory: function (signatureAreaId) {
			this.getSignatory(this, signatureAreaId).then(signatory => {
				return this.updateSignatureArea(signatureAreaId, { signatory })
			})
		},
		getSignatory: function (context, signatureAreaId = null) {
			let signatory = {}
			if (signatureAreaId) {
				const signatureArea = this.findSignatureArea(signatureAreaId)
				signatory = signatureArea.signatory
			}
			return new Promise((res, rej) => {
				const componentInstance = Vue.extend(AddSignatoryComponent)
				const component = new componentInstance({
					propsData: {
						value: signatory
					},
					parent: context
				}).$mount()
				document.getElementById('app').appendChild(component.$el)
				component.$on('close', () => {
					document.getElementById('app').removeChild(component.$el)
					component.$destroy()
					rej('Cancelled')
				})
				component.$on('input', event => {
					document.getElementById('app').removeChild(component.$el)
					component.$destroy()
					res(event)
				})
			})
		},
		handleResize: function () {
			this.computeFieldDimensions()
		},
		onClick: function (event) {
			event.stopPropagation()
		},
		onDragStart: function (event) {
			this.showActions = false
			const rect = document.getElementById(`signature-field-${this.model.id}`).getBoundingClientRect()

			let offsetLeft = event.clientX - rect.x
			let offsetTop = event.clientY - rect.y

			event.dataTransfer.effectAllowed = 'move'
			event.dataTransfer.setData('id', this.model.id)
			event.dataTransfer.setData('offsetX', offsetLeft)
			event.dataTransfer.setData('offsetY', offsetTop)
		},
		resizeObserverHandler: function () {
			this.computeAreaPosition()
			this.computeFieldDimensions()
		}
	}
}
</script>

<style scoped>
.signature-container {
	cursor: initial;
	position: absolute;
}

.signature-container > :first-child {
	background-color: rgba(var(--v-primary-transparent), 0.5);
	border: 2px solid var(--v-primary-base);
	cursor: move;
}

.signature-container > :first-child p {
	margin: 0 !important;
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.signature-container > :last-child {
	z-index: 2;
}

.signature-container > :last-child :deep(.v-btn) {
	margin: 2px;
}
</style>
