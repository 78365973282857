import { render, staticRenderFns } from "./SignatureArea.vue?vue&type=template&id=0f9a02e1&scoped=true"
import script from "./SignatureArea.vue?vue&type=script&lang=js"
export * from "./SignatureArea.vue?vue&type=script&lang=js"
import style0 from "./SignatureArea.vue?vue&type=style&index=0&id=0f9a02e1&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f9a02e1",
  null
  
)

export default component.exports